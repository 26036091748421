/**
 * Map name for mutations
 * @types {string}
 */

export const SET_INFLU_PRODUCTS_BY_CATEGORIES = "public_landingpage/SET_INFLU_PRODUCTS_BY_CATEGORIES";

export const SET_INFLU_DATA = "public_landingpage/SET_INFLU_DATA";

export const SET_COMPANIE_DETAILS = "public_landingpage/SET_COMPANIE_DETAILS";
