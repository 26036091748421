
export const SET_PRODUCTS = "my_lists/SET_PRODUCTS";

export const SET_RESEARCHING_PRODUCT = "my_lists/SET_RESEARCHING_PRODUCT"

export const SET_FILTERS = "my_lists/SET_FILTERS";

export const SET_SELECTED_FILTERS = "my_lists/SET_SELECTED_FILTERS";

export const SET_SEARCH_TEXT = "my_lists/SET_TEXT_SEARCH"

export const SET_PARENTS_ID = "my_lists/SET_PARENTS_ID"

export const SET_INFLUENCER_ID = "my_lists/SET_INFLUENCER_ID"

export const SET_FILTER_LIKES = "my_lists/SET_FILTER_LIKES"

export const SET_FILTER_KEYS = "my_lists/SET_FILTER_KEYS"

export const SET_PRICE_RANGE = "my_lists/SET_PRICE_RANGE"

export const SET_PARTNER_LANDING_LINK = "my_lists/SET_PARTNER_LANDING_LINK"

export const SET_ORDER_BY = "my_lists/SET_ORDER_BY"

export const SET_PAYLOAD_PRODUCTS = "my_lists/SET_PAYLOAD_PRODUCTS"

export const SET_LOADING = "my_lists/SET_LOADING"

export const SET_LOADING_ADDING_ONE_PRODUCT = "my_lists/SET_LOADING_ADDING_ONE_PRODUCT"

export const SET_CURRENT_PAGE = "my_lists/SET_CURRENT_PAGE"

export const SET_SELECTED_ALL_PRODUCTS = "my_lists/SET_SELECTED_ALL_PRODUCTS"

export const SET_DRAWER = "my_lists/SET_DRAWER"