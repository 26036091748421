import checkAuth from "./middleware/checkAuth";
import { deleteSwitchCompanies } from "@/shared/helpers/switchCompaniesHelper";
import checkPartnerSlug from "./middleware/checkPartnerSlug";
import { getLocalSlug } from "@/modules/partner/storage";
import { setLocalToken } from "@/modules/auth/storage";
import { removeAllOldData } from "../modules/auth/pages/sign-up/v2/components/setup";
import { setCustomLayout } from "@/shared/helpers/customLayoutHelper";
import { baseURL } from "@/shared/http/api";
import axios from "axios";
import { getParams } from "../shared/helpers/getParams";
import { deleteLocalToken } from "../modules/auth/storage";

// import { useGtm } from "@/shared/helpers/gtmHelper";

const fetchParam = (param, slug) =>
  axios.get(`${baseURL}/api/v1/parameters/p/${slug}/${param}`);

const LOGIN_ROUTE_NAME = "login";
const NOT_FOUND_ROUTE_NAME = "404";
const DASHBOARD_ROUTE_NAME = "dashboard";
const SET_TOKEN_ROUTE_NAME = "save.token";

/**
 * @param to
 * @param from
 * @param next
 * @returns {Promise<*>}
 */
export default async (to, from, next) => {
  let currentFullPath = to.fullPath,
  currentRouteName = to.name,
  routeData = currentFullPath.split("/");

  if ( currentRouteName === "login" || currentFullPath.includes('/recover/confirm') ) {
    deleteSwitchCompanies();
    deleteLocalToken()
    await checkPartnerSlug(to.params.slug);

    return next();
  }

  if (routeData.includes("sign-up")) removeAllOldData();

  if (to.name === SET_TOKEN_ROUTE_NAME) {
    await fetchParam("custom_layout", to.params.slug).then(({ data }) =>
      setCustomLayout(data.value)
    );

    setLocalToken(to.params.token);
    deleteSwitchCompanies();

    return next({ name: DASHBOARD_ROUTE_NAME });
  }

  /**
   * If route slug need persisted, persist and return view 404 if not found
   */
  if (to.meta.persistSlug && !(await checkPartnerSlug(to.params.slug)))
    return next({ name: NOT_FOUND_ROUTE_NAME });

  if (!routeData.includes("sign-up")) {
    const userLogged = await checkAuth();
    const route_need_auth = !!to.meta.noAuth === false;

    /**
     * If the route is not free, and the user is not logged in, redirect to login
     */
    if (route_need_auth && !userLogged) {
      const slug = getLocalSlug(),
        isPWA = getParams("pwa");

      if (isPWA) {
        return next({ name: LOGIN_ROUTE_NAME, params: { slug: "flipnet" } });
      }

      if (slug) {
        return next({ name: LOGIN_ROUTE_NAME, params: { slug: slug } });
      } else {
        return next({ name: NOT_FOUND_ROUTE_NAME });
      }
    }

    /**
     * If the user is already logged in, redirect to home
     */
    if (to.name === LOGIN_ROUTE_NAME && userLogged)
      return next({ name: DASHBOARD_ROUTE_NAME });
  }

  return next();
};
