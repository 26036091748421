import { each } from "lodash";
/**
 * @param token
 * @returns {boolean}
 */
export const hasToken = ({ token }) => !!token;

/**
 *
 * @param user
 * @returns {*}
 */
export const getPhoto = ({ user }) => user.partner.logo_foto;

/**
 * @param user
 * @returns {string}
 */
export const getFirstLatter = ({ user }) => user.nome[0] || "?";



/**
 *
 * @param user
 * @return {Object|undefined}
 */
export const getCluster = ({ user }) => user?.partner?.cluster;



/**
 *
 * @param user
 * @return {*}
 */
export const getFirstname = ({ user }) => user.nome.split(" ")[0] || user.nome;

/**
 *
 * @param user
 * @returns {*|null}
 */
export const userPartnerId = ({ user }) => user.parceiro || null;

/**
 *
 * @param user
 * @returns {*|null}
 */
export const userSlug = ({ user }) => user.partner.slug || null;

/**
 *
 * @param user
 * @returns {*|null}
 */
export const userEmail = ({ user }) => user.email || null;

/**
 *
 * @param user
 * @returns {*|null}
 */
export const userEmailHidden = (state, getters) => {
  if (getters.userEmail) {
    let replaced = "";
    for (let i = -1; i < getters.userEmail.length; i++) {
      if (getters.userEmail.charAt(i) === "@") i = getters.userEmail.length;
      else if (i > 2) replaced += getters.userEmail.charAt(i);
    }

    return getters.userEmail.replace(replaced, "*".repeat(replaced.length));
  }
  return null;
};

/**
 *
 * @param user
 * @returns {*|null}
 */
export const userId = ({ user }) => user.id || null;

/**
 * getter to skeleton description
 *
 * @param sign_up_skeleton
 * @returns {*|{}}
 */
export const getSignUpSkeleton = ({ sign_up_skeleton }) =>
  sign_up_skeleton || {};

export const getSignUpSkeletonChallengeCodes = ({ sign_up_skeleton }) =>
  sign_up_skeleton.challenge_links || {};

export const getSignUpCnaes = ({ sign_up_skeleton }) =>
  sign_up_skeleton.cnaes || {};

export const getSignUpPartnerTypes = ({ sign_up_skeleton }) =>
  sign_up_skeleton.partner_types || {};

export const getSignUpCategories = ({ sign_up_skeleton }) =>
  sign_up_skeleton.categories_to_publish || {};

export const getSignUpConfig = ({ sign_up_skeleton }) => {
  return (
    {
      slug_placeholder: sign_up_skeleton.params.sign_slug_placeholder,
      message_link: sign_up_skeleton.params.sign_message_link_store,
    } || {}
  );
};

export const getSignUpConfigLinkCupom = ({ sign_up_skeleton }) => {
  return {
    store_link_prefix: sign_up_skeleton.params2.sign_link_sufix,
  };
};

export const getSignUpSocialNetworks = () => {
  return [
    {
      value: "facebook",
      label: "Facebook",
    },
    {
      value: "instagram",
      label: "Instagram",
    },
    {
      value: "twitter",
      label: "Twitter",
    },
    {
      value: "linkedin",
      label: "Linkedin",
    },
    {
      value: "youtube",
      label: "Youtube",
    },
    {
      value: "whatsapp",
      label: "Whatsapp",
    },
    {
      value: "tiktok",
      label: "Tiktok",
    },
    {
      value: "site",
      label: "Site",
    },
  ];
};
export const getRegisterPersonType = ({ sign_up_skeleton }) => {
  let types = ["PF", "PJ"];
  if (
    sign_up_skeleton.params &&
    sign_up_skeleton.params.user_who_can_register
  ) {
    let value =
      sign_up_skeleton.params.user_who_can_register.value.toUpperCase();
    if (value === "PF") {
      types = ["PF"];
    } else if (value === "PJ") {
      types = ["PJ"];
    }
  }
  return types;
};

/**
 * export getter to skeleton sign up params
 *
 * @param sign_up_skeleton
 * @return {number}
 */
export const getSignUpCreateApprovedStatus = ({ sign_up_skeleton }) => {
  let status = 0;

  if (sign_up_skeleton && sign_up_skeleton.params) {
    const defaultStatusObj = sign_up_skeleton.params.default_partner_status;
    status = defaultStatusObj.value;
  }

  return status;
};

/**
 * export getter to skeleton sign up params
 *
 * @param sign_up_skeleton
 * @return {number}
 */
export const getSlugIsReadyOnly = ({ sign_up_skeleton }) => {
  let status = false;
  if (sign_up_skeleton && sign_up_skeleton.params) {
    each(sign_up_skeleton.params, (p) => {
      if (p.var === "slug_readonly") {
        status = p.value.toString() === "true";
      }
    });
  }
  return status;
};

export const getSignupFlipPartnerType = ({ partner_type_flip_signup }) => {
  return partner_type_flip_signup;
};
