import { baseURL } from "@/shared/http/api";

export default {
    getFilters: () => ({
        method: 'GET',
        url: '/api/v1/products/filterBarByParent/{parent_id}{params}',
        baseURL: baseURL,
        noPersist: true
    }),

    getProducts: () => ({
        method: 'POST',
        url: '/api/v1/products/queryProd',
        baseURL: baseURL,
        noPersist: true
    }),

    getPartnerLandingLink: () => ({
        method: 'GET',
        url: '/api/v1/parameters/partnerLandingLink',
        baseURL: baseURL,
        noPersist: true
    })
}
