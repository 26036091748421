export default {
  cnaes: {
    0: "Válidos para parceiro MEI",
    1: "Válidos para parceiro ME",
    type: {
      0: "mei",
      1: "me",
    },
  },

  partner_types: {
    pf: "Pessoa física",
    pj: "Pessoa jurídica",
  },

  fields: {
    email: "Email",
    complete_name: "Nome completo",
    invalid_fullname: "Insira seu Nome completo",
    fantasy_name: "Nome fantasia",
    celular: "Celular",
    cpf: "CPF",
    document_cpf_contact: "CPF do contato principal",
    cnpj: "CNPJ",
    birth_date: "Data de nascimento",
    invalid_birth_date: "Data de nascimento inválida",
    slug: "Digite o identificador para sua lojinha/cupom",
    slug_alpha_numeric:
      "Por favor, digite apenas letras e números (sem acentos ou caracteres especiais)",

    captions: {
      email: "Um email com sua senha será enviado para o endereço.",
      slug: "Coloque uma palavra que faça lembrar você a seus seguidores e clientes.",
    },
  },

  top_messages: {
    steps:
      "<span class='text-weight-bolder text-black'>Crie sua conta</span><br /><div class='text-weight-light text-black'>Em apenas <span class='text-uppercase text-weight-bold text-pink-4'>3 passos</span></div>",
    success:
      "<span class='text-weight-bolder text-black'>Parabéns!</span><br /><div class='text-weight-light'><span class='text-weight-bold text-pink-4'>Cadastro finalizado!</span></div>",
  },

  success_page: {
    email:
      "Suas informações de acesso foram enviadas para o seu email: <span class='text-blue'>{ email }</span><br/><br/><b>ATENÇÃO! VOCÊ TERÁ ATÉ 24H PARA CONFIRMAR SEU CADASTRO E CRIAR SUA SENHA.</b>",
    first_access:
      "<p class='text-weight-bold'>Quando você entrar pela primeira vez, que tal dar uma olhada em nossos principais recursos?</p><p>Você pode criar e personalizar suas lojas online, acompanhar o que está rolando com as empresas, realizar missões e treinamentos profissionalizantes...</p><p>E acredite, estamos aqui para ajudar em <span class='text-weight-bold'>tudo!</span></p><p>Se precisar de uma mãozinha, é só chamar nossa equipe de suporte.</p>",
    support: "Fale conosco",
    action: "Acessar minha loja",
  },

  opt_in: "Autorizo o uso do meu email e celular para comunicados.",
  use_terms: "Concordo com os termos de uso.",
  help_message: `Ao clicar no botão 'próximo', você concorda com os <a class="anchor-link-external" href="https://flip.net.br/termos_de_uso/" target="_blank"><span class="text-weight-bolder">Termos e Condições de Uso</span></a> e <a class="anchor-link-external" href="https://flip.net.br/privacidade/" target="_blank"><span class="text-weight-bolder">Política de Privacidade da Plataforma</span></a>.`,

  required: "O campo {field} é obrigatório",
};
