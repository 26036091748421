export const getFilters = ({ filters }) => {

    const priceRangeHasValues = filters?.price_range?.data?.min_price && 
    filters?.price_range?.data?.max_price;
    
    if ( !priceRangeHasValues ) {
        delete filters.price_range
    }

    return filters
};

export const getResearchingProduct = ({ researching_product }) => researching_product;

export const getPartnerLandingLink = ({ partner_landing_link }) => partner_landing_link;

export const getDrawer = ({ drawer }) => drawer;

export const getProducts = ({ products }) => products;

export const getSelectedAllProducts = ({ select_all_products }) => select_all_products;

export const getPayloadProducts = ({ payloadProducts }) => payloadProducts;

export const getSearchText = ({ text_search }) => text_search;

export const getIndividualFilters = ({ selectedFilters }) => selectedFilters;

export const getParentsID = ({ parent_ids }) => parent_ids;

export const getInfluencerID = ({ influencer_id }) => influencer_id;

export const getFilterLikes = ({ filter_likes }) => filter_likes

export const getFilterKeys = ({ filter_keys }) => filter_keys;

export const getPriceRange = ({ filter_price_range }) => filter_price_range;

export const getOrderBy = ({ order_by }) => order_by

export const getLoading = ({ loading_searching }) => loading_searching

export const getLoadingAddingOneProduct = ({ loading_adding_one_product }) => loading_adding_one_product

export const getCurrentPage = ({ current_page }) => current_page

export const getPagination = ({ products }) => {
    let pagination = Object.assign({ current_page: 1 }, products);
    
    delete pagination.data;
    return pagination;
};