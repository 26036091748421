import { baseUrlCC, baseUrlAdmin, baseUrlXmailing } from "@/shared/http/api";

/**
 * @account-services
 */
export default {
  validatePassword: () => ({
    method: "POST",
    url: "api/v1/user/validate-password",
  }),

  validatePIX: () => ({
    method: "POST",
    url: "/api/v1/starkbank/validatePix",
    baseURL: baseUrlCC,
  }),

  /**
   * api get list of partner ethnicity
   * @api-version 1
   * @returns {{method: string, url: string}}
   */
  getEthnicity: () => ({
    method: "GET",
    url: "api/v1/ethnicity/list",
    noPersist: true,
  }),

  updateUser: () => ({ method: "POST", url: "api/v1/user" }),

  deletePhoto: () => ({ method: "POST", url: "api/v1/partner/delete-photo" }),

  listBanks: () => ({ method: "GET", url: "api/v1/bank" }),

  changePassword: () => ({
    method: "POST",
    url: "api/v1/user/change-password",
  }),

  disable: () => ({ method: "DELETE", url: "api/v1/user/disable" }),

  userDocs: () => ({
    method: "GET",
    url: "api/v1/partner-doc/list",
    noPersist: true,
  }),

  docTypes: () => ({ method: "GET", url: "api/v1/partner-doc-type/list" }),

  uploadDocs: () => ({ method: "POST", url: "api/v1/partner-doc" }),

  params: () => ({
    method: "GET",
    url: "api/v1/parameters/byKeys",
    baseURL: baseUrlAdmin,
  }),

  partnerChallenges: () => ({
    method: "GET",
    url: "api/v1/partner-challenges",
    baseURL: baseUrlAdmin,
  }),

  sendAndUpdateChallenge: () => ({
    method: "GET",
    url: "api/challenge/{type}/sendAndUpdate/{value}",
    baseURL: baseUrlXmailing,
  }),

  sendChallenge: () => ({
    method: "GET",
    url: "api/challenge/{type}/send",
    baseURL: baseUrlXmailing,
  }),

  resendChallenge: () => ({
    method: "GET",
    url: "api/challenge/{type}/resend/{hash}",
    baseURL: baseUrlXmailing,
  }),

  validateChallenge: () => ({
    method: "GET",
    url: "api/challenge/{type}/validate/{hash}/{code}",
    baseURL: baseUrlXmailing,
  }),

  sendChallengeExt: () => ({
    method: "GET",
    url: "api/challengeExt/{type}/send/{hash}/{value}",
    baseURL: baseUrlXmailing,
  }),

  resendChallengeExt: () => ({
    method: "GET",
    url: "api/challengeExt/{type}/resend/{hash}",
    baseURL: baseUrlXmailing,
  }),

  validateChallengeExt: () => ({
    method: "GET",
    url: "api/challengeExt/{type}/validate/{secret}/{hash}/{code}",
    baseURL: baseUrlXmailing,
  }),
};
