import * as types from "./mutations-types";

export default {
  [types.SET_LOADING](state, payload) {
    state.loading_searching = payload;
  },

  [types.SET_LOADING_ADDING_ONE_PRODUCT](state, payload) {
    if (payload) {
      // Iniciar uma nova operação de carregamento
      state.loading_adding_one_product.activeOperationsCount++;
      state.loading_adding_one_product.status = true;
    } else {
      // Concluir uma operação de carregamento
      if (state.loading_adding_one_product.activeOperationsCount > 0) {
        state.loading_adding_one_product.activeOperationsCount--;
      }
      // Se não houver mais operações em andamento, definir como não carregando
      if (state.loading_adding_one_product.activeOperationsCount === 0) {
        state.loading_adding_one_product.status = false;
      }
    }
  },

  [types.SET_DRAWER](state, payload) {
    state.drawer = payload;
  },

  [types.SET_RESEARCHING_PRODUCT](state, payload) {
    state.researching_product = payload;
  },

  [types.SET_CURRENT_PAGE](state, payload) {
    state.current_page = payload;
  },

  [types.SET_PRODUCTS](state, payload) {
    state.products = payload;
  },

  [types.SET_SELECTED_ALL_PRODUCTS](state, payload) {
    state.select_all_products = payload;
  },
  
  [types.SET_FILTERS](state, payload) {
    state.filters = payload;
  },

  [types.SET_PRICE_RANGE](state, payload) {
    state.filter_price_range = payload;
  },
  
  [types.SET_PARTNER_LANDING_LINK](state, payload) {
    state.partner_landing_link = payload;
  },

  [types.SET_SELECTED_FILTERS](state, payload) {
    state.selectedFilters = payload;
  },

  [types.SET_PARENTS_ID](state, payload) {
    state.parent_ids = payload;
  },

  [types.SET_INFLUENCER_ID](state, payload) {
    state.influencer_id = payload;
  },

  [types.SET_FILTER_LIKES](state, payload) {
    state.filter_likes = payload;
  },

  [types.SET_FILTER_KEYS](state, payload) {
    state.filter_keys = payload;
  },

  [types.SET_ORDER_BY](state, payload) {
    state.order_by = payload;
  },

  [types.SET_SEARCH_TEXT](state, payload) {
    state.text_search = payload;
  },

  [types.SET_PAYLOAD_PRODUCTS](state, payload) {
    state.payloadProducts = payload;
  },
}