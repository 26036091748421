export const getProductsByCategories = ({ productsByCategories }) => {
  return productsByCategories;
};

export const getInfluData = ({ influ_data }) => {
  return influ_data.ecomm_customization || {};
};

export const getInfluEcomCustomization = ({ influ_data }) => {
  return influ_data || {};
};

export const getCompanieDetails = ({ company_details }) => {
  return company_details || {};
};
