import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import i18n from "@/i18n";
import './styles/utils/index.css'
import moment from "moment";
import { VueReCaptcha } from "vue-recaptcha-v3";

moment.locale("pt-br");

const reCaptchaConfigs = {
  siteKey: process.env.VUE_APP_RECAPTCHA,
  loaderOptions: {
    autoHideBadge: true,
  },
};

import Particles from "particles.vue3";
import Jquery from "jquery";


/**
 * set jquery function
 * @type {(function(*=): *)|*}
 */
window.$ = Jquery

createApp(App)
.use(store)
.use(Particles)
.use(Quasar, quasarUserOptions)
.use(i18n)
.use(router)
.use(VueReCaptcha, reCaptchaConfigs)
.mount("#app");
