/**
 * Map name for mutations
 * @types {string}
 */

export const SET_BANKS = "ACCOUNT/SET_BANKS";

export const SET_BANK_FILTER = "ACCOUNT/SET_BANK_FILTER";

export const SET_DOCUMENTS = "ACCOUNT/SET_DOCUMENTS";

export const SET_DOC_TYPES = "ACCOUNT/SET_DOC_TYPES";

export const SET_PARAMS = "ACCOUNT/SET_PARAMS";

export const SET_PARTNER_CHALLENGES = "ACCOUNT/SET_PARTNER_CHALLENGES";

export const SET_CHALLENGE_CODE = "ACCOUNT/SET_CHALLENGE_CODE";

export const SET_CHALLENGE_EXT_SECRET = "ACCOUNT/SET_CHALLENGE_EXT_SECRET";

