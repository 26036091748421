import * as types from "./mutation-types";

export default {
  /**
   * @param state
   * @param payload
   */
  [types.SET_INFLU_PRODUCTS_BY_CATEGORIES](state, payload) {
    state.productsByCategories = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_INFLU_DATA](state, payload) {
    state.influ_data = payload;
  },

  /**
   * @param state
   * @param payload
   */
  [types.SET_COMPANIE_DETAILS](state, payload) {
    state.company_details = payload;
  },
};
